import Cookies from "js-cookie"

export function getCookieValue(key: string) {
  return Cookies.get(key)
}

/**
 * @param {Number} duration How long to store the cookie in days
 */
export function createCookie(key: string, value: string, duration = 0) {
  if (!key || !value) return

  const now = new Date().getTime()
  const expire = now + 60 * 60 * 24 * 1000 * duration

  Cookies.set(key, value, {
    path: "/",
    expires: duration ? new Date(expire) : null,
  })
}
