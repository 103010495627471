// import "styles/index.scss"
import "styles/globals.css"

import GlobalStyles from "@styles/GlobalStyles"
import { MARKETING_FIELDS } from "lib/constants"
import { createCookie } from "lib/cookies"
import type { AppProps } from "next/app"
import { useRouter } from "next/router"
import { appWithTranslation } from "next-i18next"
import { useMemo, useState } from "react"
// import GlobalStyles from "styles/GlobalStyles"

/**
 * Fix for Chrome: Turns off transitions during load to prevent
 * transition animations from unstyled content to styled content.
 * See: https://github.com/vercel/next.js/issues/25487
 */
const ChromeFixUnstyledTransitions = () => {
  const [allowTransitions, setAllowTransitions] = useState(false)

  // Run this once during render.
  useMemo(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("load", function () {
        setAllowTransitions(true)
      })
    }
  }, [])

  if (allowTransitions) {
    return null
  }

  return (
    <style
      dangerouslySetInnerHTML={{
        __html: ` *, *::before, *::after { transition: none!important; } `,
      }}
    />
  )
}

function App({ Component, pageProps }: AppProps) {
  const { query } = useRouter()

  useMemo(() => {
    MARKETING_FIELDS.forEach(field => {
      const value = query[field]
      if (value) {
        if (typeof value === "string") createCookie(field, value)
        else createCookie(field, value.join(","))
      }
    })
  }, [query])

  return (
    <>
      <GlobalStyles />
      <Component {...pageProps} />
      {/* <ChromeFixUnstyledTransitions /> */}
    </>
  )
}

export default appWithTranslation(App)
