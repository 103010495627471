import { Poppins, Roboto } from "next/font/google"

export const bodyFont = Roboto({
  weight: ["400"],
  subsets: ["latin"],
})

export const headingFont = Poppins({
  weight: ["500", "600", "700"],
  style: ["normal", "italic"],
  subsets: ["latin"],
})

export default function GlobalStyles() {
  return (
    <>
      <style jsx global>
        {`
          :root {
            --font-body: ${bodyFont.style.fontFamily};
            --font-heading: ${headingFont.style.fontFamily};
            --font-fallback: Impact;
          }
        `}
      </style>
    </>
  )
}
